<template>
    <div class="row">
        <div class="col-md-12 dimensions-form">
            <label for="projectName" class="form-label">{{ $t('project_name') }}</label>
            <div class="input-group input-group-sm mb-3">
                <input type="text" class="form-control" id="projectName" aria-label="Project Name" v-model="projectName">
            </div>
            <label for="length" class="form-label">{{ $t('length') }}</label>
            <div class="input-group input-group-sm mb-3">
                <input type="text" class="form-control" id="length" aria-label="Length" v-model="length">
                <span class="input-group-text">m</span>
            </div>
            <label for="width" class="form-label">{{ $t('width') }}</label>
            <div class="input-group input-group-sm mb-3">
                <input type="text" class="form-control" id="width" aria-label="Width" v-model="width">
                <span class="input-group-text">m</span>
            </div>
            <label for="height" class="form-label">{{ $t('height') }}</label>
            <div class="input-group input-group-sm mb-3">
                <input type="text" class="form-control" id="height" aria-label="Height" v-model="height">
                <span class="input-group-text">m</span>
            </div>
            <label for="temperature" class="form-label">{{ $t('temperature') }}</label>
            <div class="input-group input-group-sm mb-3">
                <input type="text" class="form-control" id="temperature" aria-label="Temperature" v-model="temperature">
                <span class="input-group-text">℃</span>
            </div>
            <label for="targetValue" class="form-label">{{ $t('target_value') }}</label>
            <span class="info-tooltip ms-1" data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('target_value_tooltip_text')"><font-awesome-icon :icon="['fas', 'fa-question-circle']" /></span>
            <div class="input-group input-group-sm mb-3">
                <input type="number" class="form-control" id="targetValue" aria-label="targetValue" min="0" max="100" v-model="targetValue">
            </div>
            <button type="button" class="btn btn-sm btn-danger mb-3" :class="[!this.$store.state.projectName || !this.$store.state.length || !this.$store.state.width || !this.$store.state.height || !this.$store.state.temperature || !this.$store.state.targetValue ? 'disabled' : '']" @click.prevent="navigateToMaterialSettings">{{ $t('material_settings') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuildingDimension',
    data() {
        return {
            projectName: this.$store.state.projectName,
            length: this.$store.state.length,
            width: this.$store.state.width,
            height: this.$store.state.height,
            temperature: this.$store.state.temperature,
            targetValue: this.$store.state.targetValue,
        }
    },
    mounted() {
        this.$store.dispatch('updateApplied', false);
        this.$store.dispatch('updateRoof', []);
    },
    methods:{
        navigateToMaterialSettings() {
            this.$router.push('/material-settings');
        }
    },
    watch: {
        projectName(newValue) {
            this.$store.dispatch('updateProjectName', newValue);
        },
        length(newValue) {
            this.$store.dispatch('updateLength', newValue);
        },
        width(newValue) {
            this.$store.dispatch('updateWidth', newValue);
        },
        height(newValue) {
            this.$store.dispatch('updateHeight', newValue);
        },
        temperature(newValue) {
            this.$store.dispatch('updateTemperature', newValue);
        },
        targetValue(newValue) {
            this.$store.dispatch('updateTargetValue', newValue);
        }
    }
}
</script>

<style scoped>
    .bg-color-box {
        width: 5px;
        height: 5px;
        display: inline-block;
        vertical-align: middle;
    }
</style>
