<template>
    <div class="modal fade" id="customMaterialFormModal" tabindex="-1" aria-labelledby="customMaterialFormModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="customMaterialFormModalLabel">{{ $t('sound_absorption_coefficient') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="saveAbsorptionCoefficient">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <input type="text" class="form-control form-control-sm" id="name" v-model.trim="name" :class="[isSubmitted ? (!this.validateCustomMaterialForm() && validationErrors['name'] ? 'is-invalid': 'is-valid') : '']">
                                <div class="invalid-feedback" :class="[isSubmitted && !this.validateCustomMaterialForm() && validationErrors['name'] ? 'd-block': '']">
                                    {{ $t('the_name_field_is_required') }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="accm4000" class="form-label">4000 Hz</label>
                                <input type="text" class="form-control form-control-sm" id="accm4000" v-model.trim="accm4000" :class="[isSubmitted ? (!this.validateCustomMaterialForm() && validationErrors['accm4000'] ? 'is-invalid': 'is-valid') : '']">
                                <div class="invalid-feedback" :class="[isSubmitted && !this.validateCustomMaterialForm() && validationErrors['accm4000'] ? 'd-block': '']">
                                    {{ $t('value_must_be_number') }}
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="accm2000" class="form-label">2000 Hz</label>
                                <input type="text" class="form-control form-control-sm" id="accm2000" v-model.trim="accm2000" :class="[isSubmitted ? (!this.validateCustomMaterialForm() && validationErrors['accm2000'] ? 'is-invalid': 'is-valid') : '']">
                                <div class="invalid-feedback" :class="[isSubmitted && !this.validateCustomMaterialForm() && validationErrors['accm2000'] ? 'd-block': '']">
                                    {{ $t('value_must_be_number') }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="accm1000" class="form-label">1000 Hz</label>
                                <input type="text" class="form-control form-control-sm" id="accm1000" v-model.trim="accm1000" :class="[isSubmitted ? (!this.validateCustomMaterialForm() && validationErrors['accm1000'] ? 'is-invalid': 'is-valid') : '']">
                                <div class="invalid-feedback" :class="[isSubmitted && !this.validateCustomMaterialForm() && validationErrors['accm1000'] ? 'd-block': '']">
                                    {{ $t('value_must_be_number') }}
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="accm500" class="form-label">500 Hz</label>
                                <input type="text" class="form-control form-control-sm" id="accm500" v-model.trim="accm500" :class="[isSubmitted ? (!this.validateCustomMaterialForm() && validationErrors['accm500'] ? 'is-invalid': 'is-valid') : '']">
                                <div class="invalid-feedback" :class="[isSubmitted && !this.validateCustomMaterialForm() && validationErrors['accm500'] ? 'd-block': '']">
                                    {{ $t('value_must_be_number') }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="accm250" class="form-label">250 Hz</label>
                                <input type="text" class="form-control form-control-sm" id="accm250" v-model.trim="accm250" :class="[isSubmitted ? (!this.validateCustomMaterialForm() && validationErrors['accm250'] ? 'is-invalid': 'is-valid') : '']">
                                <div class="invalid-feedback" :class="[isSubmitted && !this.validateCustomMaterialForm() && validationErrors['accm250'] ? 'd-block': '']">
                                    {{ $t('value_must_be_number') }}
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="accm125" class="form-label">125 Hz</label>
                                <input type="text" class="form-control form-control-sm" id="accm125" v-model.trim="accm125" :class="[isSubmitted ? (!this.validateCustomMaterialForm() && validationErrors['accm125'] ? 'is-invalid': 'is-valid') : '']" @keyup="calculateAccm63()">
                                <div class="invalid-feedback" :class="[isSubmitted && !this.validateCustomMaterialForm() && validationErrors['accm125'] ? 'd-block': '']">
                                    {{ $t('value_must_be_number') }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="accm63" class="form-label">63 Hz</label>
                                <div>
                                    <input type="text" class="form-control form-control-sm" id="accm63" v-model.trim="accm63" :class="[isSubmitted ? (!this.validateCustomMaterialForm() && validationErrors['accm63'] ? 'is-invalid': 'is-valid') : '']" style="display: inline; width: 85%;">
                                    <span class="info-tooltip ms-1 float-end" data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('63_hz_tooltip_text')"><font-awesome-icon :icon="['fas', 'fa-question-circle']" /></span>
                                </div>
                                <div class="invalid-feedback" :class="[isSubmitted && !this.validateCustomMaterialForm() && validationErrors['accm63'] ? 'd-block': '']">
                                    {{ $t('value_must_be_number') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('close') }}</button>
                        <button type="submit" class="btn btn-danger">{{ $t('save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { Modal } from 'bootstrap';
    import i18n from '../i18n';
    const { t } = i18n.global;

    export default {
        name: 'CustomMaterialForm',
        props: {
            customMaterialName: String,
            ac63cm: String,
            ac125cm: String,
            ac250cm: String,
            ac500cm: String,
            ac1000cm: String,
            ac2000cm: String,
            ac4000cm: String,
        },
        data() {
            return {
                name: '',
                accm63: (this.ac125cm * 0.7).toFixed(2),
                accm125: this.ac125cm,
                accm250: this.ac250cm,
                accm500: this.ac500cm,
                accm1000: this.ac1000cm,
                accm2000: this.ac2000cm,
                accm4000: this.ac4000cm,
                isSubmitted: false,
                validationErrors: {},
            }
        },
        mounted() {
            let customMaterialFormModalEl = document.getElementById('customMaterialFormModal');
            let self = this;

            customMaterialFormModalEl.addEventListener('hidden.bs.modal', function () {
                self.resetCustomMaterialForm();
            });
        },
        computed: {
            formValidated() {
                return this.validateCustomMaterialForm();
            }
        },
        watch: {
            customMaterialName: {
                immediate: true,
                handler(newVal) {
                    this.name = newVal;
                }
            }
        },
        methods: {
            saveAbsorptionCoefficient() {
                this.isSubmitted = true;

                if (this.formValidated) {
                    this.$store.dispatch('updateCustomMaterialName', this.name);
                    this.$store.dispatch('updateAc63Cm', this.accm63);
                    this.$store.dispatch('updateAc125Cm', this.accm125);
                    this.$store.dispatch('updateAc250Cm', this.accm250);
                    this.$store.dispatch('updateAc500Cm', this.accm500);
                    this.$store.dispatch('updateAc1000Cm', this.accm1000);
                    this.$store.dispatch('updateAc2000Cm', this.accm2000);
                    this.$store.dispatch('updateAc4000Cm', this.accm4000);

                    this.hideCustomMaterialForm();

                    this.$notify({
                        type: 'success',
                        text: t('custom_material_saved'),
                    });
                }
            },
            hideCustomMaterialForm() {
                let customMaterialFormModal = Modal.getInstance(document.getElementById('customMaterialFormModal'));
                customMaterialFormModal.hide();
            },
            resetCustomMaterialForm() {
                this.isSubmitted = false;
                this.name = this.customMaterialName;
                this.accm63 = this.ac63cm;
                this.accm125 = this.ac125cm;
                this.accm250 = this.ac250cm;
                this.accm500 = this.ac500cm;
                this.accm1000 = this.ac1000cm;
                this.accm2000 = this.ac2000cm;
                this.accm4000 = this.ac4000cm;
            },
            validateCustomMaterialForm() {
                if (this.name == '') {
                    this.validationErrors['name'] = true;
                } else {
                    delete this.validationErrors['name'];
                }

                if (this.accm63 == '' || !this.allowDigitsAndDot(this.accm63)) {
                    this.validationErrors['accm63'] = true;
                } else {
                    delete this.validationErrors['accm63'];
                }

                if (this.accm125 == '' || !this.allowDigitsAndDot(this.accm125)) {
                    this.validationErrors['accm125'] = true;
                } else {
                    delete this.validationErrors['accm125'];
                }

                if (this.accm250 == '' || !this.allowDigitsAndDot(this.accm250)) {
                    this.validationErrors['accm250'] = true;
                } else {
                    delete this.validationErrors['accm250'];
                }

                if (this.accm500 == '' || !this.allowDigitsAndDot(this.accm500)) {
                    this.validationErrors['accm500'] = true;
                } else {
                    delete this.validationErrors['accm500'];
                }

                if (this.accm1000 == '' || !this.allowDigitsAndDot(this.accm1000)) {
                    this.validationErrors['accm1000'] = true;
                } else {
                    delete this.validationErrors['accm1000'];
                }

                if (this.accm2000 == '' || !this.allowDigitsAndDot(this.accm2000)) {
                    this.validationErrors['accm2000'] = true;
                } else {
                    delete this.validationErrors['accm2000'];
                }

                if (this.accm4000 == '' || !this.allowDigitsAndDot(this.accm4000)) {
                    this.validationErrors['accm4000'] = true;
                } else {
                    delete this.validationErrors['accm4000'];
                }

                if (Object.keys(this.validationErrors).length > 0) {
                    return false;
                }

                return true;
            },
            allowDigitsAndDot(val) {
                const rgx = /^-?[0-9]*\.?[0-9]*$/;
                return val.toString().match(rgx);
            },
            calculateAccm63() {
                this.accm63 = (this.accm125 * 0.7).toFixed(2);
            }
        }
    }
</script>

<style scoped>
</style>
