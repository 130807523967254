import { createWebHistory, createRouter } from "vue-router";
import BuildingDimension from '@/components/BuildingDimension.vue';
import MaterialSettings from '@/components/MaterialSettings.vue';
import store from '../store';

const routes = [
    {
        path: "/",
        name: "building-dimension",
        component: BuildingDimension,
    },
    {
        path: "/material-settings",
        name: "material-settings",
        component: MaterialSettings,
        beforeEnter: () => {
            if (!store.state.projectName 
                || !store.state.length 
                || !store.state.width 
                || !store.state.height 
                || !store.state.temperature
                || !store.state.targetValue) {
                return { name: 'building-dimension' } 
            }
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;