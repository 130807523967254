<template>
    <div class="modal fade" id="infoTextModal" tabindex="-1" aria-labelledby="infoTextModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="infoTextModalLabel">{{ $t('acoustic_configurator') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="mb-1">{{ $t('acoustic_configurator_tooltip_text_1') }}</p>
                    <p class="mb-1">{{ $t('acoustic_configurator_tooltip_text_2') }}</p>
                    <p class="mb-1">{{ $t('acoustic_configurator_tooltip_text_3') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InfoText',
    }
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  .modal {
    padding: 50px !important;
  }

  .modal-dialog {
    margin-right: 0 !important;
    margin-top: 0 !important;
  }
}
</style>
