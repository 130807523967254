<template>
    <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="contactModalLabel">{{ $t('contact_us') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="sendEmail">
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="name" class="form-label">{{ $t('name') }}</label>
                            <input type="text" class="form-control" id="name" v-model="name" :class="[isSubmitted ? (!name ? 'is-invalid': 'is-valid') : '']">
                            <div class="invalid-feedback" :class="[isSubmitted && !name ? 'd-block': '']">
                                {{ $t('the_name_field_is_required') }}
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">{{ $t('email_address') }}</label>
                            <input type="email" class="form-control" id="email" v-model="email" :class="[isSubmitted ? (!email || !validEmail ? 'is-invalid': 'is-valid') : '']">
                            <div class="invalid-feedback" :class="[isSubmitted && (!email || !validEmail) ? 'd-block': '']">
                                {{ !email ? $t('the_email_field_is_required') : $t('the_email_is_not_valid') }}
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label">{{ $t('phone_number') }}</label>
                            <input type="text" class="form-control" id="phone" v-model="phone">
                        </div>
                        <div class="mb-3">
                            <vue-recaptcha ref="recaptcha" :sitekey="siteKey" @verify="verifyReCaptcha" @expired="expiredReCaptcha" @error="errorReCaptcha"></vue-recaptcha>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('close') }}</button>
                        <button type="submit" class="btn btn-danger" :disabled="isDisabled">{{ $t('send') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { Modal } from 'bootstrap';
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        name: 'ContactForm',
        components: {
            VueRecaptcha
        },
        props: {
            chartLabels: {
                type: Array,
                required: true
            },
            chartData: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                name: '',
                email: '',
                phone: '',
                isSubmitted: false,
                isDisabled: true,
                siteKey: '6Ldqm2ofAAAAABsxyLQCGcjzH2agcb7kyc2ujVms',
            }
        },
        mounted() {
            let contactModalEl = document.getElementById('contactModal');
            let self = this;

            contactModalEl.addEventListener('hidden.bs.modal', function () {
                self.isSubmitted = false;
                self.resetContactForm();
                self.$refs.recaptcha.reset();
                self.isDisabled = true;
            });
        },
        computed: {
            validEmail() {
                let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regex.test(this.email);
            }
        },
        methods: {
            verifyReCaptcha() {
                this.isDisabled = false;
            },
            expiredReCaptcha() {
                this.$refs.recaptcha.reset();
                this.isDisabled = true;
            },
            errorReCaptcha() {
                this.$refs.recaptcha.reset();
                this.isDisabled = true;
            },
            sendEmail() {
                this.isSubmitted = true;
                this.isDisabled = true;

                if (!this.name || !this.email || !this.validEmail) {
                    this.isDisabled = false;
                    return false;
                }

                axios.post(process.env.VUE_APP_EMAIL_REQUEST_ENDPOINT, {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    project_name: this.$store.state.projectName,
                    length: this.$store.state.length,
                    width: this.$store.state.width,
                    height: this.$store.state.height,
                    temperature: this.$store.state.temperature,
                    target_value: this.$store.state.targetValue,
                    chart_labels: this.chartLabels,
                    chart_data: this.chartData,
                    roof: this.$store.state.roof,
                    first_gable_wall: this.$store.state.firstGableWall,
                    second_gable_wall: this.$store.state.secondGableWall,
                    first_side_wall: this.$store.state.firstSideWall,
                    second_side_wall: this.$store.state.secondSideWall
                }).then((response) => {
                    this.isSubmitted = false;
                    this.isDisabled = false;
                    this.hideContactModal();

                    this.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: response.data.message,
                    });
                }).catch((error) => {
                    console.log(error);
                    this.isSubmitted = false;
                    this.isDisabled = false;
                });
            },
            hideContactModal() {
                let contactModal = Modal.getInstance(document.getElementById('contactModal'));
                contactModal.hide();
            },
            resetContactForm() {
                this.name = '';
                this.email = '';
                this.phone = '';
            }
        }
    }
</script>

<style scoped>
</style>
