<template>
    <div v-if="errors.length" class="row material-validation-errors">
        <div class="col-md-12">
            <div class="alert alert-danger" role="alert">
                <ul class="mb-0">
                    <li class="d-flex align-items-center" v-for="(error, index) in errors" :key="index"><span class="bg-color-box rounded-circle me-1" :class="error.bgColor"></span>{{ error.message }}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="accordion mb-3" id="accordionMaterialSettings">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingRoof">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRoof" aria-expanded="false" aria-controls="collapseRoof" @click.prevent="selectMaterialTab('roof', '0x21a2ff')">
                        <span class="bg-color-box bg-21a2ff rounded-circle me-1"></span><span>{{ $t('roof') }}</span>
                    </button>
                    </h2>
                    <div id="collapseRoof" class="accordion-collapse collapse" aria-labelledby="headingRoof" data-bs-parent="#accordionMaterialSettings">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-md-3" v-for="roofMaterial in roofMaterials" :key="roofMaterial.id">
                                    <div class="text-center">
                                        <a href="#" @click.prevent="selectMaterial(roofMaterial.id, 'roof')">
                                            <img :src="require('../assets/images/product_images/' + roofMaterial.img)" class="material border border-1 rounded-circle" :class="[roofMaterial.isSeleted ? 'selected-material' : '']" alt="">
                                        </a>
                                        <span class="info-tooltip ms-1" data-bs-toggle="tooltip" data-bs-placement="top" :title="$t(`${roofMaterial.key}_tooltip_text`)"><font-awesome-icon :icon="['fas', 'fa-question-circle']" /></span>
                                    </div>
                                    <div class="text-center">
                                        <label class="form-label">
                                            <a href="#" class="text-reset text-decoration-none" @click.prevent="selectMaterial(roofMaterial.id, 'roof')">
                                                <span v-if="roofMaterial.key == 'custom_material' && this.$store.state.customMaterialName">{{ this.$store.state.customMaterialName }}</span>
                                                <span v-else>{{ $t(roofMaterial.name) }}</span>
                                            </a>
                                        </label>
                                    </div>
                                    <div v-if="roofMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" :id="`roofProduct${roofMaterial.id}percentage`" :aria-label="roofMaterial.name" v-model="roofMaterial.percentage" @keyup="calculateArea(roofMaterial.id, 'roof')">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div v-if="roofMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" :id="`roofProduct${roofMaterial.id}m2`" :aria-label="roofMaterial.name" v-model="roofMaterial.area" @keyup="calculatePercentage(roofMaterial.id, 'roof')">
                                        <span class="input-group-text">m2</span>
                                    </div>
                                    <div v-if="roofMaterial.key == 'custom_material' && roofMaterial.isSeleted" class="text-center">
                                        <a href="#" class="btn btn-sm btn-danger mb-3 custom-material-edit-btn" @click.prevent="showCustomMaterialForm()"><font-awesome-icon :icon="['far', 'fa-edit']" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingGableWallOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGableWallOne" aria-expanded="false" aria-controls="collapseGableWallOne" @click.prevent="selectMaterialTab('gableWallOne', '0xff0000')">
                        <span class="bg-color-box bg-ff0000 rounded-circle me-1"></span><span>{{ $t('gable_wall') }}</span>
                    </button>
                    </h2>
                    <div id="collapseGableWallOne" class="accordion-collapse collapse" aria-labelledby="headingGableWallOne" data-bs-parent="#accordionMaterialSettings">
                        <div class="accordion-body">
                           <div class="row">
                                <div class="col-md-3" v-for="gableWallOneMaterial in gableWallOneMaterials" :key="gableWallOneMaterial.id">
                                    <div class="text-center">
                                        <a href="#" @click.prevent="selectMaterial(gableWallOneMaterial.id, 'gableWallOne')">
                                            <img :src="require('../assets/images/product_images/' + gableWallOneMaterial.img)" class="material border border-1 rounded-circle" :class="[gableWallOneMaterial.isSeleted ? 'selected-material' : '']" alt="">
                                        </a>
                                        <span class="info-tooltip ms-1" data-bs-toggle="tooltip" data-bs-placement="top" :title="$t(`${gableWallOneMaterial.key}_tooltip_text`)"><font-awesome-icon :icon="['fas', 'fa-question-circle']" /></span>
                                    </div>
                                    <div class="text-center">
                                        <label class="form-label">
                                            <a href="#" class="text-reset text-decoration-none" @click.prevent="selectMaterial(gableWallOneMaterial.id, 'gableWallOne')">
                                                <span v-if="gableWallOneMaterial.key == 'custom_material' && this.$store.state.customMaterialName">{{ this.$store.state.customMaterialName }}</span>
                                                <span v-else>{{ $t(gableWallOneMaterial.name) }}</span>
                                            </a>
                                        </label>
                                    </div>
                                    <div v-if="gableWallOneMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" id="`gwOneProduct${gableWallOneMaterial.id}percentage`" :aria-label="gableWallOneMaterial.name" v-model="gableWallOneMaterial.percentage" @keyup="calculateArea(gableWallOneMaterial.id, 'gableWallOne')">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div v-if="gableWallOneMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" id="`gwOneProduct${gableWallOneMaterial.id}m2`" :aria-label="gableWallOneMaterial.name" v-model="gableWallOneMaterial.area" @keyup="calculatePercentage(gableWallOneMaterial.id, 'gableWallOne')">
                                        <span class="input-group-text">m2</span>
                                    </div>
                                    <div v-if="gableWallOneMaterial.key == 'custom_material' && gableWallOneMaterial.isSeleted" class="text-center">
                                        <a href="#" class="btn btn-sm btn-danger mb-3 custom-material-edit-btn" @click.prevent="showCustomMaterialForm()"><font-awesome-icon :icon="['far', 'fa-edit']" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingGableWallTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGableWallTwo" aria-expanded="false" aria-controls="collapseGableWallTwo" @click.prevent="selectMaterialTab('gableWallTwo', '0xedbc41')">
                        <span class="bg-color-box bg-edbc41 rounded-circle me-1"></span><span>{{ $t('gable_wall') }}</span>
                    </button>
                    </h2>
                    <div id="collapseGableWallTwo" class="accordion-collapse collapse" aria-labelledby="headingGableWallTwo" data-bs-parent="#accordionMaterialSettings">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-md-3" v-for="gableWallTwoMaterial in gableWallTwoMaterials" :key="gableWallTwoMaterial.id">
                                    <div class="text-center">
                                        <a href="#" @click.prevent="selectMaterial(gableWallTwoMaterial.id, 'gableWallTwo')">
                                            <img :src="require('../assets/images/product_images/' + gableWallTwoMaterial.img)" class="material border border-1 rounded-circle" :class="[gableWallTwoMaterial.isSeleted ? 'selected-material' : '']" alt="">
                                        </a>
                                        <span class="info-tooltip ms-1" data-bs-toggle="tooltip" data-bs-placement="top" :title="$t(`${gableWallTwoMaterial.key}_tooltip_text`)"><font-awesome-icon :icon="['fas', 'fa-question-circle']" /></span>
                                    </div>
                                    <div class="text-center">
                                        <label class="form-label">
                                            <a href="#" class="text-reset text-decoration-none" @click.prevent="selectMaterial(gableWallTwoMaterial.id, 'gableWallTwo')">
                                                <span v-if="gableWallTwoMaterial.key == 'custom_material' && this.$store.state.customMaterialName">{{ this.$store.state.customMaterialName }}</span>
                                                <span v-else>{{ $t(gableWallTwoMaterial.name) }}</span>
                                            </a>
                                        </label>
                                    </div>
                                    <div v-if="gableWallTwoMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" id="`gwTwoProduct${gableWallTwoMaterial.id}percentage`" :aria-label="gableWallTwoMaterial.name" v-model="gableWallTwoMaterial.percentage" @keyup="calculateArea(gableWallTwoMaterial.id, 'gableWallTwo')">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div v-if="gableWallTwoMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" id="`gwTwoProduct${gableWallTwoMaterial.id}m2`" :aria-label="gableWallTwoMaterial.name" v-model="gableWallTwoMaterial.area" @keyup="calculatePercentage(gableWallTwoMaterial.id, 'gableWallTwo')">
                                        <span class="input-group-text">m2</span>
                                    </div>
                                    <div v-if="gableWallTwoMaterial.key == 'custom_material' && gableWallTwoMaterial.isSeleted" class="text-center">
                                        <a href="#" class="btn btn-sm btn-danger mb-3 custom-material-edit-btn" @click.prevent="showCustomMaterialForm()"><font-awesome-icon :icon="['far', 'fa-edit']" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSideWallOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSideWallOne" aria-expanded="false" aria-controls="collapseSideWallOne" @click.prevent="selectMaterialTab('sideWallOne', '0x59c600')">
                        <span class="bg-color-box bg-59c600 rounded-circle me-1"></span><span>{{ $t('side_wall') }}</span>
                    </button>
                    </h2>
                    <div id="collapseSideWallOne" class="accordion-collapse collapse" aria-labelledby="headingSideWallOne" data-bs-parent="#accordionMaterialSettings">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-md-3" v-for="sideWallOneMaterial in sideWallOneMaterials" :key="sideWallOneMaterial.id">
                                    <div class="text-center">
                                        <a href="#" @click.prevent="selectMaterial(sideWallOneMaterial.id, 'sideWallOne')">
                                            <img :src="require('../assets/images/product_images/' + sideWallOneMaterial.img)" class="material border border-1 rounded-circle" :class="[sideWallOneMaterial.isSeleted ? 'selected-material' : '']" alt="">
                                        </a>
                                        <span class="info-tooltip ms-1" data-bs-toggle="tooltip" data-bs-placement="top" :title="$t(`${sideWallOneMaterial.key}_tooltip_text`)"><font-awesome-icon :icon="['fas', 'fa-question-circle']" /></span>
                                    </div>
                                    <div class="text-center">
                                        <label class="form-label">
                                            <a href="#" class="text-reset text-decoration-none" @click.prevent="selectMaterial(sideWallOneMaterial.id, 'sideWallOne')">
                                                <span v-if="sideWallOneMaterial.key == 'custom_material' && this.$store.state.customMaterialName">{{ this.$store.state.customMaterialName }}</span>
                                                <span v-else>{{ $t(sideWallOneMaterial.name) }}</span>
                                            </a>
                                        </label>
                                    </div>
                                    <div v-if="sideWallOneMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" id="`swOneProduct${sideWallOneMaterial.id}percentage`" :aria-label="sideWallOneMaterial.name" v-model="sideWallOneMaterial.percentage" @keyup="calculateArea(sideWallOneMaterial.id, 'sideWallOne')">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div v-if="sideWallOneMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" id="`swOneProduct${sideWallOneMaterial.id}m2`" :aria-label="sideWallOneMaterial.name" v-model="sideWallOneMaterial.area" @keyup="calculatePercentage(sideWallOneMaterial.id, 'sideWallOne')">
                                        <span class="input-group-text">m2</span>
                                    </div>
                                    <div v-if="sideWallOneMaterial.key == 'custom_material' && sideWallOneMaterial.isSeleted" class="text-center">
                                        <a href="#" class="btn btn-sm btn-danger mb-3 custom-material-edit-btn" @click.prevent="showCustomMaterialForm()"><font-awesome-icon :icon="['far', 'fa-edit']" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSideWallTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSideWallTwo" aria-expanded="false" aria-controls="collapseSideWallTwo" @click.prevent="selectMaterialTab('sideWallTwo', '0xff35f6')">
                        <span class="bg-color-box bg-ff35f6 rounded-circle me-1"></span><span>{{ $t('side_wall') }}</span>
                    </button>
                    </h2>
                    <div id="collapseSideWallTwo" class="accordion-collapse collapse" aria-labelledby="headingSideWallTwo" data-bs-parent="#accordionMaterialSettings">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-md-3" v-for="sideWallTwoMaterial in sideWallTwoMaterials" :key="sideWallTwoMaterial.id">
                                    <div class="text-center">
                                        <a href="#" @click.prevent="selectMaterial(sideWallTwoMaterial.id, 'sideWallTwo')">
                                            <img :src="require('../assets/images/product_images/' + sideWallTwoMaterial.img)" class="material border border-1 rounded-circle" :class="[sideWallTwoMaterial.isSeleted ? 'selected-material' : '']" alt="">
                                        </a>
                                        <span class="info-tooltip ms-1" data-bs-toggle="tooltip" data-bs-placement="top" :title="$t(`${sideWallTwoMaterial.key}_tooltip_text`)"><font-awesome-icon :icon="['fas', 'fa-question-circle']" /></span>
                                    </div>
                                    <div class="text-center">
                                        <label class="form-label">
                                            <a href="#" class="text-reset text-decoration-none" @click.prevent="selectMaterial(sideWallTwoMaterial.id, 'sideWallTwo')">
                                                <span v-if="sideWallTwoMaterial.key == 'custom_material' && this.$store.state.customMaterialName">{{ this.$store.state.customMaterialName }}</span>
                                                <span v-else>{{ $t(sideWallTwoMaterial.name) }}</span>
                                            </a>
                                        </label>
                                    </div>
                                    <div v-if="sideWallTwoMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" id="`swTwoProduct${sideWallTwoMaterial.id}percentage`" :aria-label="sideWallTwoMaterial.name" v-model="sideWallTwoMaterial.percentage" @keyup="calculateArea(sideWallTwoMaterial.id, 'sideWallTwo')">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div v-if="sideWallTwoMaterial.isSeleted" class="input-group input-group-sm mb-3">
                                        <input type="text" class="form-control" id="`swTwoProduct${sideWallTwoMaterial.id}m2`" :aria-label="sideWallTwoMaterial.name" v-model="sideWallTwoMaterial.area" @keyup="calculatePercentage(sideWallTwoMaterial.id, 'sideWallTwo')">
                                        <span class="input-group-text">m2</span>
                                    </div>
                                    <div v-if="sideWallTwoMaterial.key == 'custom_material' && sideWallTwoMaterial.isSeleted" class="text-center">
                                        <a href="#" class="btn btn-sm btn-danger mb-3 custom-material-edit-btn" @click.prevent="showCustomMaterialForm()"><font-awesome-icon :icon="['far', 'fa-edit']" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-end">
            <button type="button" class="btn btn-sm btn-danger" :disabled="isDisabledApply" @click.prevent="applyMaterailSettings">{{ $t('apply') }}</button>
        </div>
    </div>
    <CustomMaterialForm :customMaterialName="customMaterialName" :ac63cm="this.$store.state.ac63cm" :ac125cm="this.$store.state.ac125cm" :ac250cm="this.$store.state.ac250cm" :ac500cm="this.$store.state.ac500cm" :ac1000cm="this.$store.state.ac1000cm" :ac2000cm="this.$store.state.ac2000cm" :ac4000cm="this.$store.state.ac4000cm" />
</template>

<script>
    import i18n from '../i18n';
    const { t } = i18n.global;
    import { Modal } from 'bootstrap';
    import CustomMaterialForm from '@/components/CustomMaterialForm.vue';

    export default {
        name: 'MaterialSettings',
        components: {
            CustomMaterialForm
        },
        data() {
            return {
                roofMaterials: [
                    { id: 1, key: 'concrete_structure', name: 'concrete_structure', img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.length) * Number(this.$store.state.width), areaFraction: 1, isSeleted: true},
                    { id: 2, key: 'ruukki_t153_acub', name: 'ruukki_t153_acub', img: 'ruukki_t153_acub.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 3, key: 'ruukki_T153_acub_acoustic_infill', name: 'ruukki_T153_acub_acoustic_infill', img: 'ruukki_T153_acub_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 4, key: 'ruukki_t130m_3_15', name: 'ruukki_t130m_3_15', img: 'ruukki_t130m_3_15.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 5, key: 'ruukki_t130_3_15_acoustic_infill', name: 'ruukki_t130_3_15_acoustic_infill', img: 'ruukki_t130_3_15_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 6, key: 'ruukki_sound_absortive_wall', name: 'ruukki_sound_absortive_wall', img: 'ruukki_sound_absortive_wall.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 7, key: 'light_weight_structure', name: 'light_weight_structure', img: 'light_weight_structure.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 8, key: 'custom_material', name: 'custom_material', img: 'custom_material.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 9, key: 'doors_and_windows', name: 'doors_and_windows', img: 'doors_and_windows.png', percentage: '', area: '', areaFraction: '', isSeleted: false}
                ],
                gableWallOneMaterials: [
                    { id: 1, key: 'concrete_structure', name: 'concrete_structure', img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.width) * Number(this.$store.state.height), areaFraction: 1, isSeleted: true},
                    { id: 2, key: 'ruukki_t153_acub', name: 'ruukki_t153_acub', img: 'ruukki_t153_acub.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 3, key: 'ruukki_T153_acub_acoustic_infill', name: 'ruukki_T153_acub_acoustic_infill', img: 'ruukki_T153_acub_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 4, key: 'ruukki_t130m_3_15', name: 'ruukki_t130m_3_15', img: 'ruukki_t130m_3_15.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 5, key: 'ruukki_t130_3_15_acoustic_infill', name: 'ruukki_t130_3_15_acoustic_infill', img: 'ruukki_t130_3_15_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 6, key: 'ruukki_sound_absortive_wall', name: 'ruukki_sound_absortive_wall', img: 'ruukki_sound_absortive_wall.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 7, key: 'light_weight_structure', name: 'light_weight_structure', img: 'light_weight_structure.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 8, key: 'custom_material', name: 'custom_material', img: 'custom_material.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 9, key: 'doors_and_windows', name: 'doors_and_windows', img: 'doors_and_windows.png', percentage: '', area: '', areaFraction: '', isSeleted: false}
                ],
                gableWallTwoMaterials: [
                    { id: 1, key: 'concrete_structure', name: 'concrete_structure', img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.width) * Number(this.$store.state.height), areaFraction: 1, isSeleted: true},
                    { id: 2, key: 'ruukki_t153_acub', name: 'ruukki_t153_acub', img: 'ruukki_t153_acub.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 3, key: 'ruukki_T153_acub_acoustic_infill', name: 'ruukki_T153_acub_acoustic_infill', img: 'ruukki_T153_acub_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 4, key: 'ruukki_t130m_3_15', name: 'ruukki_t130m_3_15', img: 'ruukki_t130m_3_15.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 5, key: 'ruukki_t130_3_15_acoustic_infill', name: 'ruukki_t130_3_15_acoustic_infill', img: 'ruukki_t130_3_15_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 6, key: 'ruukki_sound_absortive_wall', name: 'ruukki_sound_absortive_wall', img: 'ruukki_sound_absortive_wall.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 7, key: 'light_weight_structure', name: 'light_weight_structure', img: 'light_weight_structure.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 8, key: 'custom_material', name: 'custom_material', img: 'custom_material.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 9, key: 'doors_and_windows', name: 'doors_and_windows', img: 'doors_and_windows.png', percentage: '', area: '', areaFraction: '', isSeleted: false}
                ],
                sideWallOneMaterials: [
                    { id: 1, key: 'concrete_structure', name: 'concrete_structure', img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.length) * Number(this.$store.state.height), areaFraction: 1, isSeleted: true},
                    { id: 2, key: 'ruukki_t153_acub', name: 'ruukki_t153_acub', img: 'ruukki_t153_acub.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 3, key: 'ruukki_T153_acub_acoustic_infill', name: 'ruukki_T153_acub_acoustic_infill', img: 'ruukki_T153_acub_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 4, key: 'ruukki_t130m_3_15', name: 'ruukki_t130m_3_15', img: 'ruukki_t130m_3_15.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 5, key: 'ruukki_t130_3_15_acoustic_infill', name: 'ruukki_t130_3_15_acoustic_infill', img: 'ruukki_t130_3_15_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 6, key: 'ruukki_sound_absortive_wall', name: 'ruukki_sound_absortive_wall', img: 'ruukki_sound_absortive_wall.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 7, key: 'light_weight_structure', name: 'light_weight_structure', img: 'light_weight_structure.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 8, key: 'custom_material', name: 'custom_material', img: 'custom_material.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 9, key: 'doors_and_windows', name: 'doors_and_windows', img: 'doors_and_windows.png', percentage: '', area: '', areaFraction: '', isSeleted: false}
                ],
                sideWallTwoMaterials: [
                    { id: 1, key: 'concrete_structure', name: 'concrete_structure', img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.length) * Number(this.$store.state.height), areaFraction: 1, isSeleted: true},
                    { id: 2, key: 'ruukki_t153_acub', name: 'ruukki_t153_acub', img: 'ruukki_t153_acub.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 3, key: 'ruukki_T153_acub_acoustic_infill', name: 'ruukki_T153_acub_acoustic_infill', img: 'ruukki_T153_acub_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 4, key: 'ruukki_t130m_3_15', name: 'ruukki_t130m_3_15', img: 'ruukki_t130m_3_15.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 5, key: 'ruukki_t130_3_15_acoustic_infill', name: 'ruukki_t130_3_15_acoustic_infill', img: 'ruukki_t130_3_15_acoustic_infill.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 6, key: 'ruukki_sound_absortive_wall', name: 'ruukki_sound_absortive_wall', img: 'ruukki_sound_absortive_wall.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 7, key: 'light_weight_structure', name: 'light_weight_structure', img: 'light_weight_structure.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 8, key: 'custom_material', name: 'custom_material', img: 'custom_material.png', percentage: '', area: '', areaFraction: '', isSeleted: false},
                    { id: 9, key: 'doors_and_windows', name: 'doors_and_windows', img: 'doors_and_windows.png', percentage: '', area: '', areaFraction: '', isSeleted: false}
                ],
                isDisabledApply: false,
                roofSelMaterials: [
                    { id: 1, key: 'concrete_structure', name: t('concrete_structure'), img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.length) * Number(this.$store.state.width), areaFraction: 1, isSeleted: true}
                ],
                gableWallOneSelMaterials: [
                    { id: 1, key: 'concrete_structure', name: t('concrete_structure'), img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.width) * Number(this.$store.state.height), areaFraction: 1, isSeleted: true},
                ],
                gableWallTwoSelMaterials: [
                    { id: 1, key: 'concrete_structure', name: t('concrete_structure'), img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.width) * Number(this.$store.state.height), areaFraction: 1, isSeleted: true},
                ],
                sideWallOneSelMaterials: [
                    { id: 1, key: 'concrete_structure', name: t('concrete_structure'), img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.length) * Number(this.$store.state.height), areaFraction: 1, isSeleted: true},
                ],
                sideWallTwoSelMaterials: [
                    { id: 1, key: 'concrete_structure', name: t('concrete_structure'), img: 'concrete_structure.png', percentage: 100, area: Number(this.$store.state.length) * Number(this.$store.state.height), areaFraction: 1, isSeleted: true},
                ],
                roof: [],
                firstGableWall: [],
                secondGableWall: [],
                firstSideWall: [],
                secondSideWall: [],
                errors: [],
                customMaterialName: ''
            }
        },
        mounted() {
            this.$store.dispatch('updateSecondSideWallColor', '');
            this.$store.dispatch('updateRoofColor', '');
            this.$store.dispatch('updateFirstGableWallColor', '');
            this.$store.dispatch('updateSecondGableWallColor', '');
            this.$store.dispatch('updateFirstSideWallColor', '');
        },
        updated() {
            if (this.roofSelMaterials.length > 0 && 
            this.gableWallOneSelMaterials.length > 0 && 
            this.gableWallTwoSelMaterials.length > 0 && 
            this.sideWallOneSelMaterials.length > 0 && 
            this.sideWallTwoSelMaterials.length > 0) {
                this.isDisabledApply = false;
            } else {
                this.isDisabledApply = true;
                this.$store.dispatch('updateApplied', false);
                this.$store.dispatch('updateRoof', []);
                this.$store.dispatch('updateFirstGableWall', []);
                this.$store.dispatch('updateSecondGableWall', []);
                this.$store.dispatch('updateFirstSideWall', []);
                this.$store.dispatch('updateSecondSideWall', []);
            }
        },
        methods: {
            selectMaterial(id, type) {
                if (type == 'roof') {
                    for (let roofIndex in this.roofMaterials) {
                        if (this.roofMaterials[roofIndex].id == id) {
                            this.processSelectMaterial(this.roofMaterials[roofIndex], this.roofSelMaterials);
                        }
                    }
                } else if (type == 'gableWallOne') {
                    for (let gwOneIndex in this.gableWallOneMaterials) {
                        if (this.gableWallOneMaterials[gwOneIndex].id == id) {
                            this.processSelectMaterial(this.gableWallOneMaterials[gwOneIndex], this.gableWallOneSelMaterials);
                        }
                    }
                } else if (type == 'gableWallTwo') {
                    for (let gwTwoIndex in this.gableWallTwoMaterials) {
                        if (this.gableWallTwoMaterials[gwTwoIndex].id == id) {
                            this.processSelectMaterial(this.gableWallTwoMaterials[gwTwoIndex], this.gableWallTwoSelMaterials);
                        }
                    }
                } else if (type == 'sideWallOne') {
                    for (let swOneIndex in this.sideWallOneMaterials) {
                        if (this.sideWallOneMaterials[swOneIndex].id == id) {
                            this.processSelectMaterial(this.sideWallOneMaterials[swOneIndex], this.sideWallOneSelMaterials);
                        }
                    }
                } else if (type == 'sideWallTwo') {
                    for (let swTwoIndex in this.sideWallTwoMaterials) {
                        if (this.sideWallTwoMaterials[swTwoIndex].id == id) {
                            this.processSelectMaterial(this.sideWallTwoMaterials[swTwoIndex], this.sideWallTwoSelMaterials);
                        }
                    }
                }
            },
            processSelectMaterial(material, selectedMaterial) {
                if (material.isSeleted) {
                    material.isSeleted = false;
                    material.percentage = '';
                    material.area = '';
                    material.areaFraction = '';
                    selectedMaterial.pop();
                } else {
                    material.isSeleted = true;
                    selectedMaterial.push(material); 
                }
            },
            calculateArea(id, type) {
                let roofArea = Number(this.$store.state.length) * Number(this.$store.state.width);
                let gableWallArea = Number(this.$store.state.width) * Number(this.$store.state.height);
                let sideWallArea = Number(this.$store.state.length) * Number(this.$store.state.height);
                
                if (type == 'roof') {
                    for (let roofIndex in this.roofMaterials) {
                        if (this.roofMaterials[roofIndex].id == id) {
                            this.processAreaCalculation(roofArea, this.roofMaterials[roofIndex]);
                        }
                    }
                } else if (type == 'gableWallOne') {
                    for (let gwOneIndex in this.gableWallOneMaterials) {
                        if (this.gableWallOneMaterials[gwOneIndex].id == id) {
                            this.processAreaCalculation(gableWallArea, this.gableWallOneMaterials[gwOneIndex]);
                        }
                    }
                } else if (type == 'gableWallTwo') {
                    for (let gwTwoIndex in this.gableWallTwoMaterials) {
                        if (this.gableWallTwoMaterials[gwTwoIndex].id == id) {
                            this.processAreaCalculation(gableWallArea, this.gableWallTwoMaterials[gwTwoIndex]);
                        }
                    }
                } else if (type == 'sideWallOne') {
                    for (let swOneIndex in this.sideWallOneMaterials) {
                        if (this.sideWallOneMaterials[swOneIndex].id == id) {
                            this.processAreaCalculation(sideWallArea, this.sideWallOneMaterials[swOneIndex]);
                        }
                    }
                } else if (type == 'sideWallTwo') {
                    for (let swTwoIndex in this.sideWallTwoMaterials) {
                        if (this.sideWallTwoMaterials[swTwoIndex].id == id) {
                            this.processAreaCalculation(sideWallArea, this.sideWallTwoMaterials[swTwoIndex]);
                        }
                    }
                }
            },
            processAreaCalculation(area, material) {
                let percentage = Number(material.percentage);

                if (percentage > 100) {
                    material.percentage = 100;
                    percentage = 100;
                }

                let percentageFraction = Number(percentage) / 100;
                let materialArea = area * percentageFraction;

                material.area = materialArea;
                material.areaFraction = percentageFraction;
            },
            calculatePercentage(id, type) {
                let roofArea = Number(this.$store.state.length) * Number(this.$store.state.width);
                let gableWallArea = Number(this.$store.state.width) * Number(this.$store.state.height);
                let sideWallArea = Number(this.$store.state.length) * Number(this.$store.state.height);
                
                if (type == 'roof') {
                    for (let roofIndex in this.roofMaterials) {
                        if (this.roofMaterials[roofIndex].id == id) {
                            this.processPercentageCalculation(roofArea, this.roofMaterials[roofIndex]);
                        }
                    }
                } else if (type == 'gableWallOne') {
                    for (let gwOneIndex in this.gableWallOneMaterials) {
                        if (this.gableWallOneMaterials[gwOneIndex].id == id) {
                            this.processPercentageCalculation(gableWallArea, this.gableWallOneMaterials[gwOneIndex]);
                        }
                    }
                } else if (type == 'gableWallTwo') {
                    for (let gwTwoIndex in this.gableWallTwoMaterials) {
                        if (this.gableWallTwoMaterials[gwTwoIndex].id == id) {
                            this.processPercentageCalculation(gableWallArea, this.gableWallTwoMaterials[gwTwoIndex]);
                        }
                    }
                } else if (type == 'sideWallOne') {
                    for (let swOneIndex in this.sideWallOneMaterials) {
                        if (this.sideWallOneMaterials[swOneIndex].id == id) {
                            this.processPercentageCalculation(sideWallArea, this.sideWallOneMaterials[swOneIndex]);
                        }
                    }
                } else if (type == 'sideWallTwo') {
                    for (let swTwoIndex in this.sideWallTwoMaterials) {
                        if (this.sideWallTwoMaterials[swTwoIndex].id == id) {
                            this.processPercentageCalculation(sideWallArea, this.sideWallTwoMaterials[swTwoIndex]);
                        }
                    }
                }
            },
            processPercentageCalculation(area, material) {
                let materialArea = Number(material.area);
                            
                if (materialArea > area) {
                    material.area = area;
                    materialArea = area;
                }

                let areaFraction = area / materialArea;
                let percentage = 100 / areaFraction;
                let percentageFraction = Number(percentage) / 100;

                material.percentage = percentage;
                material.areaFraction = percentageFraction;
            },
            applyMaterailSettings() {
                
                let roofArea = Number(this.$store.state.length) * Number(this.$store.state.width);
                let gableWallArea = Number(this.$store.state.width) * Number(this.$store.state.height);
                let sideWallArea = Number(this.$store.state.length) * Number(this.$store.state.height);

                this.roof = this.processSelectedMaterials(roofArea, this.roofMaterials);
                this.firstGableWall = this.processSelectedMaterials(gableWallArea, this.gableWallOneMaterials);
                this.secondGableWall = this.processSelectedMaterials(gableWallArea, this.gableWallTwoMaterials);
                this.firstSideWall = this.processSelectedMaterials(sideWallArea, this.sideWallOneMaterials);
                this.secondSideWall = this.processSelectedMaterials(sideWallArea, this.sideWallTwoMaterials);
                
                this.checkValidationErrors(this.roof, this.firstGableWall, this.secondGableWall, this.firstSideWall, this.secondSideWall);
                
                if (this.errors.length > 0) {
                    return false;
                }

                if (this.roof.length > 0 && 
                    this.firstGableWall.length > 0 && 
                    this.secondGableWall.length > 0 && 
                    this.firstSideWall.length > 0 && 
                    this.secondSideWall.length > 0
                ) {
                    this.$store.dispatch('updateRoof', this.roof);
                    this.$store.dispatch('updateFirstGableWall', this.firstGableWall);
                    this.$store.dispatch('updateSecondGableWall', this.secondGableWall);
                    this.$store.dispatch('updateFirstSideWall', this.firstSideWall);
                    this.$store.dispatch('updateSecondSideWall', this.secondSideWall);
                    this.$store.dispatch('updateApplied', true);
                }
            },
            processSelectedMaterials(area, materials) {
                let selectedMaterials = [];
                let selectedEmptyMaterials = [];
                let usedPercentage = 0;
                let usedArea = 0;

                for (let index in materials) {
                    if (materials[index].isSeleted) {
                        if (materials[index].areaFraction) {
                            selectedMaterials.push(materials[index]);
                            usedArea += Number(materials[index].area);
                            usedPercentage += Number(materials[index].percentage);
                        } else {
                            selectedEmptyMaterials.push(materials[index]);
                        }
                    }
                }

                let remainingPercentage = 100 - usedPercentage;
                let remainingArea = area - usedArea;

                if (selectedEmptyMaterials.length > 0) {
                    let emptyMaterialPercentage = remainingPercentage / selectedEmptyMaterials.length;
                    let emptyMaterialArea = remainingArea / selectedEmptyMaterials.length;
                    
                    for (let indx in selectedEmptyMaterials) {
                        selectedEmptyMaterials[indx].percentage = emptyMaterialPercentage;
                        selectedEmptyMaterials[indx].area = emptyMaterialArea;
                        selectedEmptyMaterials[indx].areaFraction = emptyMaterialPercentage / 100;
                    }
                }

                return selectedMaterials.concat(selectedEmptyMaterials);
            },
            checkValidationErrors(roofMaterials, gableWallOneMaterials, gableWallTwoMaterials, sideWallOneMaterials, sideWallTwoMaterials) {
                this.errors = [];

                if (this.countPercentage(roofMaterials) > 100) {
                    this.errors.push({name: 'roof', message: t('percentage_cannot_more_100_area_than_roof_area'), bgColor: 'bg-21a2ff'});
                } else if (this.countPercentage(roofMaterials) < 100) {
                    this.errors.push({name: 'roof', message: t('percentage_cannot_less_100_area_than_roof_area'), bgColor: 'bg-21a2ff'});
                } else {
                    this.errors = this.errors.filter(data => data.name !== 'roof');
                }

                if (this.countPercentage(gableWallOneMaterials) > 100) {
                    this.errors.push({name: 'gableWallOne', message: t('percentage_cannot_more_100_area_than_gable_wall_area'), bgColor: 'bg-ff0000'});
                } else if (this.countPercentage(gableWallOneMaterials) < 100) {
                    this.errors.push({name: 'gableWallOne', message: t('percentage_cannot_less_100_area_than_gable_wall_area'), bgColor: 'bg-ff0000'});
                } else {
                    this.errors = this.errors.filter(data => data.name !== 'gableWallOne');
                }

                if (this.countPercentage(gableWallTwoMaterials) > 100) {
                    this.errors.push({name: 'gableWallTwo', message: t('percentage_cannot_more_100_area_than_gable_wall_area'), bgColor: 'bg-edbc41'});
                } else if (this.countPercentage(gableWallTwoMaterials) < 100) {
                    this.errors.push({name: 'gableWallTwo', message: t('percentage_cannot_less_100_area_than_gable_wall_area'), bgColor: 'bg-edbc41'});
                } else {
                    this.errors = this.errors.filter(data => data.name !== 'gableWallTwo');
                }

                if (this.countPercentage(sideWallOneMaterials) > 100) {
                    this.errors.push({name: 'sideWallOne', message: t('percentage_cannot_more_100_area_than_side_wall_area'), bgColor: 'bg-59c600'});
                } else if (this.countPercentage(sideWallOneMaterials) < 100) {
                    this.errors.push({name: 'sideWallOne', message: t('percentage_cannot_less_100_area_than_side_wall_area'), bgColor: 'bg-59c600'});
                } else {
                    this.errors = this.errors.filter(data => data.name !== 'sideWallOne');
                }

                if (this.countPercentage(sideWallTwoMaterials) > 100) {
                    this.errors.push({name: 'sideWallTwo', message: t('percentage_cannot_more_100_area_than_side_wall_area'), bgColor: 'bg-ff35f6'});
                } else if (this.countPercentage(sideWallTwoMaterials) < 100) {
                    this.errors.push({name: 'sideWallTwo', message: t('percentage_cannot_less_100_area_than_side_wall_area'), bgColor: 'bg-ff35f6'});
                } else {
                    this.errors = this.errors.filter(data => data.name !== 'sideWallTwo');
                }
            },
            countPercentage(materials) {
                let countPercentage = 0;

                for (let m in materials) {
                    if (materials[m].isSeleted) {
                        countPercentage += Number(materials[m].percentage);
                    }
                }

                return countPercentage;
            },
            selectMaterialTab(type, color) {
                if (type == 'roof') {
                    this.$store.dispatch('updateRoofColor', color);
                    this.$store.dispatch('updateFirstGableWallColor', '');
                    this.$store.dispatch('updateSecondGableWallColor', '');
                    this.$store.dispatch('updateFirstSideWallColor', '');
                    this.$store.dispatch('updateSecondSideWallColor', '');
                }

                if (type == 'gableWallOne') {
                    this.$store.dispatch('updateFirstGableWallColor', color);
                    this.$store.dispatch('updateRoofColor', '');
                    this.$store.dispatch('updateSecondGableWallColor', '');
                    this.$store.dispatch('updateFirstSideWallColor', '');
                    this.$store.dispatch('updateSecondSideWallColor', '');
                }

                if (type == 'gableWallTwo') {
                    this.$store.dispatch('updateSecondGableWallColor', color);
                    this.$store.dispatch('updateRoofColor', '');
                    this.$store.dispatch('updateFirstGableWallColor', '');
                    this.$store.dispatch('updateFirstSideWallColor', '');
                    this.$store.dispatch('updateSecondSideWallColor', '');
                }

                if (type == 'sideWallOne') {
                    this.$store.dispatch('updateFirstSideWallColor', color);
                    this.$store.dispatch('updateRoofColor', '');
                    this.$store.dispatch('updateFirstGableWallColor', '');
                    this.$store.dispatch('updateSecondGableWallColor', '');
                    this.$store.dispatch('updateSecondSideWallColor', '');
                }

                if (type == 'sideWallTwo') {
                    this.$store.dispatch('updateSecondSideWallColor', color);
                    this.$store.dispatch('updateRoofColor', '');
                    this.$store.dispatch('updateFirstGableWallColor', '');
                    this.$store.dispatch('updateSecondGableWallColor', '');
                    this.$store.dispatch('updateFirstSideWallColor', '');
                }
            },
            showCustomMaterialForm() {
                this.customMaterialName = this.$store.state.customMaterialName ? this.$store.state.customMaterialName : t('custom_material');
                const customMaterialFormModal = new Modal(document.getElementById('customMaterialFormModal'));
                customMaterialFormModal.show();
            }
        }
    }
</script>

<style scoped>
    .accordion-button {
        padding: 0.5rem 1.25rem;
    }

    .bg-color-box {
        width: 16px;
        height: 16px;
        margin-top: 1px;
    }

    .bg-ff35f6 {
        background-color: #ff35f6 !important;
    }

    .bg-21a2ff {
        background-color: #21a2ff !important;
    }

    .bg-e43c3c {
        background-color: #e43c3c !important;
    }

    .bg-ff0000 {
        background-color: #ff0000 !important;
    }

    .bg-edbc41 {
        background-color: #edbc41 !important;
    }

    .bg-59c600 {
        background-color: #59c600 !important;
    }

    .material {
        width: 75px;
        height: 75px;
    }

    .selected-material {
        border-color: #dc3545 !important;
    }

    #accordionMaterialSettings .accordion-body .form-label a {
        font-size: 11px;
    }

    #accordionMaterialSettings .accordion-body img.rounded-circle:hover {
        border-color: #dc3545 !important;
    }

    .material-validation-errors ul {
        list-style: none;
        padding-left: 0.25rem;
    }

    .info-tooltip {
        vertical-align: bottom;
    }

    .custom-material-edit-btn {
        width: 100%;
    } 
</style>
