<template>
    <div class="position-relative">
        <div :class="this.$route.name" id="buildingWireframe" style="min-height: 315px;">
        </div>
        <span class="d-none" style="display:none;">
            {{ this.$store.state.roofColor }}
            {{ this.$store.state.firstGableWallColor }}
            {{ this.$store.state.secondGableWallColor }}
            {{ this.$store.state.firstSideWallColor }}
            {{ this.$store.state.secondSideWallColor }}
        </span>
    </div>
</template>

<script>
    import * as THREE from 'three';

    export default {
        name: 'BuildingWireframe',
        props: {
            length: {
                type: Number,
                required: true
            },
            width: {
                type: Number,
                required: true
            },
            height: {
                type: Number,
                required: true
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.drawBuildingWireframe();
            });
        },
        updated() {
            this.$nextTick(() => {
                this.drawBuildingWireframe();
            });
        },
        methods: {
            drawBuildingWireframe() {
                document.getElementById('buildingWireframe').innerHTML = '';
                const container = document.getElementById('buildingWireframe');

                const camera = new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 1, 4000);
                const maxSize = Math.max(this.width, this.height, this.length);
                const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));
                const fitWidthDistance = fitHeightDistance / camera.aspect;
                let offset = 1.50;

                if ((this.length == this.height && this.width == this.height) || (this.length == this.width) || (this.height > this.length || this.height > this.width)) {
                    offset = 1.75;
                }

                const distance = offset * Math.max(fitHeightDistance, fitWidthDistance);
                camera.position.z = distance;

                const scene = new THREE.Scene();
                const geometry = new THREE.BoxGeometry(this.width, this.height, this.length);
                const group = new THREE.Group();
                scene.add(group);
                let helper = null;

                if (this.$route.name == 'material-settings') {
                    const materials = [
                        new THREE.MeshBasicMaterial({transparent: true, opacity: 0}),
                        new THREE.MeshBasicMaterial({transparent: true, opacity: 0}),
                        new THREE.MeshBasicMaterial({transparent: true, opacity: 0}),
                        new THREE.MeshBasicMaterial({transparent: true, opacity: 0}),
                        new THREE.MeshBasicMaterial({transparent: true, opacity: 0}),
                        new THREE.MeshBasicMaterial({transparent: true, opacity: 0}),
                    ];

                    if (this.$store.state.firstSideWallColor) {
                        materials[0].color.setHex(this.$store.state.firstSideWallColor);
                        materials[0].opacity = 0.5;
                        materials[0].side = THREE.BackSide;
                        materials[1].side = THREE.BackSide;
                        materials[2].side = THREE.FrontSide;
                        materials[3].side = THREE.BackSide;
                        materials[4].side = THREE.BackSide;
                        materials[5].side = THREE.BackSide;

                    }

                    if (this.$store.state.secondSideWallColor) {
                        materials[1].color.setHex(this.$store.state.secondSideWallColor);
                        materials[1].opacity = 0.5;
                        materials[0].side = THREE.BackSide;
                        materials[1].side = THREE.FrontSide;
                        materials[2].side = THREE.BackSide;
                        materials[3].side = THREE.BackSide;
                        materials[4].side = THREE.BackSide;
                        materials[5].side = THREE.BackSide;

                    }

                    if (this.$store.state.roofColor) {
                        materials[2].color.setHex(this.$store.state.roofColor);
                        materials[2].opacity = 0.5;
                        materials[0].side = THREE.BackSide;
                        materials[1].side = THREE.BackSide;
                        materials[2].side = THREE.FrontSide;
                        materials[3].side = THREE.BackSide;
                        materials[4].side = THREE.BackSide;
                        materials[5].side = THREE.BackSide;

                    }

                    if (this.$store.state.firstGableWallColor) {
                        materials[4].color.setHex(this.$store.state.firstGableWallColor);
                        materials[4].opacity = 0.5;
                        materials[0].side = THREE.BackSide;
                        materials[1].side = THREE.BackSide;
                        materials[2].side = THREE.FrontSide;
                        materials[3].side = THREE.BackSide;
                        materials[4].side = THREE.FrontSide;
                        materials[5].side = THREE.BackSide;
                    }

                    if (this.$store.state.secondGableWallColor) {
                        materials[5].color.setHex(this.$store.state.secondGableWallColor);
                        materials[5].opacity = 0.5;
                        materials[0].side = THREE.BackSide;
                        materials[1].side = THREE.BackSide;
                        materials[2].side = THREE.BackSide;
                        materials[3].side = THREE.BackSide;
                        materials[4].side = THREE.BackSide;
                        materials[5].side = THREE.BackSide;
                    }

                    const mesh = new THREE.Mesh(geometry, materials);
                    helper = new THREE.BoxHelper(mesh);
                    group.add(mesh);
                } else {
                    const mesh = new THREE.Mesh(geometry);
                    helper = new THREE.BoxHelper(mesh);
                }

                if (helper) {
                    helper.material.color.setHex(0x6c757d);
                    helper.material.transparent = false;
                    group.add(helper);
                }

                const loader = new THREE.TextureLoader();
                const textureImage = require('../assets/images/figure_icon.png');
                const wfHeight = this.height;

                loader.load(textureImage,
                    function(texture) {
                        const textureMaterial = new THREE.SpriteMaterial({ map: texture });
                        const sprite = new THREE.Sprite(textureMaterial);

                        let scaleValue = Math.max(fitHeightDistance, fitWidthDistance) * (2/wfHeight);

                        if (scaleValue > wfHeight / 2) {
                            scaleValue = wfHeight / 2;
                        }
                      
                        sprite.position.set(maxSize + 3, maxSize * -1.5, 0);
                        sprite.scale.set(scaleValue, scaleValue, scaleValue);
                        
                        if (wfHeight > 3) {
                            group.add(sprite);
                        }

                        renderer.render(scene, camera);
                    },
                    undefined,
                    function(err) {
                        console.error('An error happened.', err);
                    }
                );

                group.rotation.x = 0.50;
                group.rotation.y = 0.75;

                const renderer = new THREE.WebGLRenderer({antialias: true});
                renderer.setPixelRatio(window.devicePixelRatio);
                renderer.setSize(container.clientWidth, container.clientHeight);
                renderer.outputEncoding = THREE.sRGBEncoding;
                container.appendChild(renderer.domElement);

                renderer.setClearColor(0xffffff);
                renderer.render(scene, camera);
            }
        },
    }
</script>

<style scoped>
</style>
