<template>
    <canvas id="resultChart"></canvas>
</template>

<script>
    import { Chart, registerables } from 'chart.js';
    Chart.register(...registerables);
    let resultLineChart;
    
    export default {
        name: 'ResultChart',
        props: {
            chartLabels: {
                type: Array,
                required: true
            },
            chartData: {
                type: Array,
                required: true
            },
            estimateLowerLimitLabel: {
                type: String,
            },
            estimateUpperLimitLabel: {
                type: String,
            },
            targetValueLabel: {
                type: String,
            },
        },
        mounted () {
            this.$nextTick(() => {
                this.drawChart();
            });
        },
        updated() {
            this.$nextTick(() => {
                this.drawChart();
            });
        },
        methods: {
            drawChart() {
                if(resultLineChart instanceof Chart) {
                    resultLineChart.destroy();
                }

                const ctx = document.getElementById('resultChart');
                resultLineChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: this.chartLabels,
                        datasets: [{
                            label: this.estimateLowerLimitLabel,
                            data: this.chartData.length > 0 ? this.chartData[0] : [],
                            pointStyle: 'rect',
                            fill: false,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                            borderWidth: 1
                        },
                        {
                            label: this.estimateUpperLimitLabel,
                            data: this.chartData.length > 1 ? this.chartData[1] : [],
                            pointStyle: 'triangle',
                            fill: false,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                            borderWidth: 1
                        },
                        {
                            label: this.targetValueLabel,
                            data: [this.$store.state.targetValue, this.$store.state.targetValue, this.$store.state.targetValue, this.$store.state.targetValue, this.$store.state.targetValue, this.$store.state.targetValue, this.$store.state.targetValue],
                            fill: false,
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 1
                        }]
                    },
                    options: {
                        scales: {
                            x: {
                                display: true,
                                title: {
                                    display: true,
                                    text: 'f [Hz]'
                                },
                            },
                            y: {
                                beginAtZero: true,
                                display: true,
                                title: {
                                    display: true,
                                    text: 'T [s]'
                                },
                            }
                        },
                        plugins: {
                            legend: {
                                position: 'right',
                                maxWidth: '226',
                                labels: {
                                    usePointStyle: true,
                                    font: {
                                        size: 9
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }
    }
</script>
<style scoped>
</style>
