import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import router from './router';
import store from './store';
import i18n from './i18n';
import Notifications from '@kyvg/vue3-notification';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

library.add(faEdit);
library.add(faQuestionCircle);

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(Notifications);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount("#app");
